<template>
    <div class="personsPage" :class="ifDelete?'':'height100'">
        <div>
            <template v-for="item in list">
                <!-- name的值以后换成id -->
                <swipe-cell :key='item.id' :disabled="!ifDelete" :name='item.id' :right-width='100' :before-close="showDelete">
                    <div  class="personBox" @click="goEditPage(item)">
                        <div class="textLine">
                            <div>
                                <img src="../../../assets/meet.png" >
                                <span class='name'>{{item.name}}</span>
                            </div>
                            <Icon name="arrow" color='#666' size='14' />
                        </div>
                        <div class="textLine bottomLine">
                            <div>
                               <span v-if="item.gender" class="mar-r-15">{{item.gender==1?'女':'男'}}</span>
                                <span>{{item.mobile}}</span>
                            </div>
                            <span class='color333'>{{item.post}}</span>
                        </div>
                    </div>
                    <template #right >
                        <Button square text="删除" type="danger" class="delete-button" />
                    </template>
                </swipe-cell>
                <!--  -->
            </template>
            <div><Divider v-if="list.length<1">暂无参加人员</Divider></div>
        </div>

        <Popup v-model="showModal" position="bottom" >
            <div class="popBox">
                <div class="phone" @click="deletePerson">删除参加人员{{deleteInfo.name}}</div>
                <div class="cancel" @click="cancelModal">取消</div>
            </div>
        </Popup>
    </div>
</template>

<script>
import { SwipeCell,Button,Icon,Divider,Popup } from 'vant';
import {
  delAttender
} from "@/api/meet.js";
    export default {
        name:'attendee',
        components:{
            SwipeCell,Button ,Icon,Divider,Popup
        },
        props:{
            list:{
                type:Array,
                default:[]
            },
            applyField:{
                type:String,
                default:""
            },
            meetId:{
                type:String,
                default:""
            },
            meetStatus:{
                type:String,
                default:""
            },
            ifDelete:{
                type:Boolean,
                default:true
            }

        },
        data(){
            return{
                showModal:false,
                deleteInfo:{
                    name:'',
                    id:''
                },
                // list:[
                //     {},{}
                // ]
            }
        },
        methods:{
            //显示删除弹窗
            showDelete({name,instance }){
                instance.close();
                this.deleteInfo=this.list.find(item=>item.id==name)
                this.showModal=true;
            },
            cancelModal(){
                this.deleteInfo={id:'',name:''};
                this.showModal=false;
            },
            //删除参会人
            deletePerson(){
                delAttender({ids:this.deleteInfo.id}).then(res=>{
                    if(res.status=="S"){
                        this.showModal=false;
                        this.$emit("updateList")
                    }
                })
            },
            goEditPage(info){
                console.log(this.meetStatus)
                this.$router.push({name:"editAttendee",params:{info,applyField:this.applyField,meetId:this.meetId,meetStatus:this.meetStatus,ifSave:this.ifDelete?'save':'noSave',ifDelete:this.ifDelete?'yes':'no'}});
            }

        },
    }
</script>

<style lang="less" scoped>
::v-deep.van-swipe-cell{
    .van-button{
        height: 100%;
        width: 100%;
    }
    .van-swipe-cell__right{
        width:100px
    }
}
::v-deep.van-divider{
    width: 150px;
    margin: 0.2rem auto;
}
::v-deep.van-popup{
    background-color: transparent;

}
.personsPage{
    background: #fff;
    width: 100%;
    height: calc(~"100vh - 3.15rem");
    overflow-y: auto;

    box-sizing: border-box;
    .personBox{
        background: #fff;
        padding:0.15rem 0.12rem;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #eee;
        .textLine{

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            color:#666;
            div{
                display: flex;
                align-items: center;
                .name{
                    color: #333;
                    font-weight: bold;
                }
            }
            img{
                width: 0.16rem;
                margin-right: 0.11rem;
            }
            .color333{
                color: #333;
                width: 45%;
                overflow: hidden;
                text-align: right;
            }

        }
        .bottomLine{
                margin-top: 0.1rem;
                padding-left: 0.25rem;
                .mar-r-15{
                    margin-right: 0.15rem;
                }
            }
    }
    .popBox{
        background-color: transparent;
        padding:0 0.12rem 0.45rem;
        div{
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            text-align: center;
            color: #2b72ff;
            font-size: 16px;
        }
        .phone{
            display: block;
            color: #F62F2F;
            height: 0.58rem;
            line-height: 0.58rem;
        }
        .cancel{
            height: 0.41rem;
            line-height: 0.41rem;
            margin-top: 0.1rem;
        }
    }

}
.height100{
    height:calc(~"100vh - 0.15rem");
    overflow-y: auto;
}
</style>
